import { utc } from 'moment';
import {
  NEXT_PUBLIC_ARKESTRO_ENV,
  NEXT_PUBLIC_SEGMENT_KEY,
  USE_INTERCOM_INTEGRATION,
  VALID_ENVIRONMENTS,
} from '@utils/constants';
import { buildSegmentUserTrackingObject } from './utils/buildUserTrackingObject';

class SegmentService {
  static initialize = () => {
    if (typeof window === 'undefined') return;
    if (!VALID_ENVIRONMENTS.includes(NEXT_PUBLIC_ARKESTRO_ENV)) return;

    if (NEXT_PUBLIC_SEGMENT_KEY) {
      /* eslint-disable no-unused-expressions, func-names, no-multi-assign, @stylistic/max-len, no-console, no-plusplus, prefer-rest-params */
      !function (){const analytics=window.analytics=window.analytics||[];

        if (!analytics.initialize) if (analytics.invoked)window.console&&console.error&&console.error('Segment snippet included twice.');else {analytics.invoked=!0;analytics.methods=['trackSubmit','trackClick','trackLink','trackForm','pageview','identify','reset','group','track','ready','alias','debug','page','once','off','on','addSourceMiddleware','addIntegrationMiddleware','setAnonymousId','addDestinationMiddleware'];analytics.factory=function (e){return function (){const t=Array.prototype.slice.call(arguments);

          t.unshift(e);analytics.push(t);return analytics;};};for (let e=0;e<analytics.methods.length;e++){const key=analytics.methods[e];

          analytics[key]=analytics.factory(key);}analytics.load=function (key,e){const t=document.createElement('script');

          t.type='text/javascript';t.async=!0;t.src=`https://cdn.segment.com/analytics.js/v1/${  key  }/analytics.min.js`;const n=document.getElementsByTagName('script')[0];

          n.parentNode.insertBefore(t,n);analytics._loadOptions=e;};analytics._writeKey=NEXT_PUBLIC_SEGMENT_KEY;analytics.SNIPPET_VERSION='5.2.0';

        // Disabling Intercom integration from loading entirely to avoid competing calls to Intercom from
        // here and our manual Intercom initialization.
        analytics.load(NEXT_PUBLIC_SEGMENT_KEY, { integrations: { Intercom: USE_INTERCOM_INTEGRATION } });
        analytics.page();
        }}();
      /* eslint-enable no-unused-expressions, func-names, no-multi-assign, @stylistic/max-len, no-console, no-plusplus, prefer-rest-params */
    }
  }

  // Segment + Intercom Docs
  // https://segment.com/docs/connections/destinations/catalog/intercom/
  static configureUser = (options = {}) => {
    if (!VALID_ENVIRONMENTS.includes(NEXT_PUBLIC_ARKESTRO_ENV)) return;

    const { currentUser } = options;

    if (typeof window === 'undefined') return;
    if (!window.analytics?.identify) return;
    if (!currentUser?.id) return;

    // When a user is not being impersonated, the true user is the same as the current user.
    const isImpersonating = currentUser.impersonating;
    const trackedUser = isImpersonating ? currentUser.true_user : currentUser;

    if (!trackedUser?.id) return;

    // As of this comment, the only integrations through Segment are:
    // Satismeter
    // Downstream, Amplitude does pull this data, but we will block email and organization name
    const organizationId = trackedUser.organization_id || 0;

    const timestamp = utc();
    const timestampUnix = timestamp.unix();

    // See comment below and IntercomService
    // const intercomParams = {
    //   user_hash : trackedUser.intercom_hmac,
    //   api_base: trackedUser.intercom_api_base,
    //   app_id: EU_INTERCOM_APP_ID,
    // };

    // if (trackedUser.emea) intercomParams.app_id = EU_INTERCOM_APP_ID;

    window.analytics.identify(
      trackedUser.id,
      buildSegmentUserTrackingObject(currentUser),
      {
        integrations: {
          Intercom : USE_INTERCOM_INTEGRATION
        }
      }
    );

    // Pass integrations as third argument to analytics.identify() if we need to load
    // Intercom via Segment. Loading through Segment has problems working with both US
    // and EU Intercom apps so we load Intercom separately in the IntercomService.
    // {
    //   integrations: {
    //     Intercom : USE_INTERCOM_INTEGRATION
    //   }
    // }

    if (organizationId) {
      window.analytics.group(organizationId);
    }
  };
}

export const attachPageAnalytics = (pageName, pageProps ) => {
  try {
    if (!VALID_ENVIRONMENTS.includes(NEXT_PUBLIC_ARKESTRO_ENV)) return;
    if (typeof window === 'undefined' || !window.analytics?.page) return;

    window.analytics.page( pageName, pageProps );
  } catch (error) {
    console.error('Error attaching page analytics', error);
  }
};

export const attachTrackingAnalytics = (actionName, actionProps) => {
  try {
    if (!VALID_ENVIRONMENTS.includes(NEXT_PUBLIC_ARKESTRO_ENV)) return;
    if (typeof window === 'undefined' || !window.analytics?.track) return;

    window.analytics.track( actionName, actionProps );
  } catch (error) {
    console.error('Error attaching tracking analytics', error);
  }
};

export default SegmentService;
