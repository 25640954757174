import NextHead from 'next/head';
import PropTypes from 'prop-types';
import {
  isDev,
  NEXT_PUBLIC_ARKESTRO_ENV,
} from '@utils/constants';

const Head = ({ title, children }) => {
  let displayTitle = `${title} - Arkestro`;

  if (!title) displayTitle = 'Arkestro';
  // Append environment name when it's not the true Production application
  // e.g. Development, Staging, Sandbox, Demo, etc.
  if (isDev) {
    displayTitle = `${displayTitle} - [${NEXT_PUBLIC_ARKESTRO_ENV.toUpperCase()}]`;
  }

  return (
    <NextHead>
      <title>{displayTitle}</title>
      {children}
    </NextHead>
  );
};

Head.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

Head.defaultProps = {
  children: null,
  title: undefined,
};

export default Head;
